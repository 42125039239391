import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w12LD03Ueben = () => (
  <Layout>
    <SEO title="w12LD03Ueben" />

    <h1 id="ueberschrift">Line Dance</h1>
    <h2>Übungskurs für bestimmte Tänze &emsp;  ©&nbsp;2019</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w12LD03Ueben&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        *Line Dance* bietet eine Vielfalt an Tänzen, welche alleine oder in der Gruppe
        zu verschiedenen Liedern getanzt werden können. Manche sind eingängiger und bei manchen
        weigern sich Kopf und Beine zusammen zu arbeiten. Im Übungskurs wird nur ein Tanz unter
        die Lupe genommen und trainiert. Je nach zur Verfügung stehender Zeit können weitere bereits
        geläufige Tänze getanzt werden.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: ca. 2x2 Meter pro Teilnehmer + mind. 2 Meter Breite extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: ca. 3x3 Meter
      </p>
      <p>
        Voraussetzungen: + Grundkenntnisse des angebotenen Tanzes +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w12LD03Ueben
